import React from 'react';

const Cancel = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-blue-100 p-8">
      <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-10">
        {/* Header */}
        <h1 className="text-3xl font-bold text-gray-800 mb-4">Cancellation and Refund Policy</h1>
        <p className="text-gray-600 mb-8">Last Updated: January 6, 2024</p>

        {/* Terms Content */}
        <div className="space-y-6 text-gray-700">
          <div>
            <h2 className="text-xl font-semibold text-gray-800">1. Cancellation</h2>
            <p>
            You may cancel your Premium account at any time; however, there are no refunds for cancellation.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-semibold text-gray-800">2. Refunds</h2>
            <p>
            We will provide refund only when our service not work for more than 7 days. In the event that PDFCroppers suspends or terminates your account or this Agreement, you understand and agree that you shall receive no refund or exchange for any credits, any unused time on a subscription and any license or subscription fees for any portion of the Service.
            </p>
          </div>

        
        </div>
      </div>
    </div>
  );
};

export default Cancel;
